<template>
  <el-container>
    <div style="margin: 0 auto; padding-top: 500px">
      <el-button type="primary" @click="chooseTime('replenishOnrSStock')"
        >进货单商品信息导出</el-button
      >
      <el-button type="warning" @click="chooseTime('sendOnrSStock')"
        >按发货模板导出</el-button
      >
      <el-button type="success" @click="chooseTime('goods')"
        >商品信息导出</el-button
      >
      <el-button
        type="info"
        @click="chooseTime('reportedLossToOverflow', false)"
        >报溢单商品信息导出</el-button
      >
      <el-button
        type="warning"
        @click="chooseTime('reportedLossToOverflow', true)"
        >报损单商品信息导出</el-button
      >
      <el-button type="danger" @click="chooseTime('shipping')"
        >销售单商品信息导出</el-button
      >
    </div>
    <el-dialog title="请选择时间" :visible.sync="showTimeDig">
      <el-date-picker
        v-model="value2"
        type="datetimerange"
        :picker-options="pickerOptions"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        align="right"
      >
      </el-date-picker>
      <div style="margin-top: 50px">
        <el-button type="success" @click="downLoadExcel">确定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import api from "../../../util/extra-api";
import { session, aa } from "../../../util/util";
import { formatTime } from "@/util/util";
export default {
  data() {
    return {
      showTimeDig: false,
      titleValue: "",
      type: true,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value2: "",
      addTime: "",
      endTime: "",
      companyId: "",
    };
  },
  mounted() {
    this.companyId = localStorage.getItem("company_id");
  },
  methods: {
    chooseTime(name, type) {
      this.showTimeDig = true;
      this.titleValue = name;
      this.type = type;
    },
    downLoadExcel() {
      this.addTime = formatTime(this.value2[0], "yyyy-MM-dd HH:mm:ss");
      this.endTime = formatTime(this.value2[1], "yyyy-MM-dd HH:mm:ss");
      const secounds = 5;
      let num = 0;
      this.timer = setInterval(() => {
        if (num < secounds) {
          num++;
          this.check(name);
        } else {
          clearInterval(this.timer);
          this.loading = false;
        }
      }, 2000);
      let statename;
      //console.debug(this.value);
      if (this.titleValue === "sendOnrSStock") {
        api
          .fileget("/v1/pc/export/export1", {
            companyId: this.companyId,
            startTime: this.addTime,
            endTime: this.endTime,
          })
          .then((v) => {});
      } else {
        statename = "信息表";
        axios
          .fileget("/pc/company-excel/" + this.titleValue + "Excel", {
            fileName: statename,
            companyId: this.companyId,
            type: this.type,
            addTime: this.addTime,
            endTime: this.endTime,
          })
          .then((v) => {});
      }
      this.showTimeDig = false;
    },
    check() {
      axios
        .get("/pc/company-excel/check", { down_name: this.titleValue })
        .then((v) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    top: 0px;
    left: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .list_tab {
    padding: 10px;
    background-color: #f5f7fa;
    .list_tag {
      right: -10px;
      padding: 2px 6px;
      border-radius: 10px;
      top: -10px;
    }
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/.el-table .aa .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
